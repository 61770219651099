<template>
    <div class="titleTop" >
        <div class="titleTopLeft">
            <div class="titleTopLeftImg">
                <img src="../assets/img/qyshop.png" alt="">
            </div>
            <div class="titleTopSearch">
                <div class="titleTopSearchInput">
                     <el-input
                        placeholder="请输入内容"
                        prefix-icon="el-icon-search"
                        v-model="value">
                    </el-input>
                     <el-button class="btn" type="primary" round>搜索</el-button>
                </div>
                <div class="titleTopCar">
                    <div class="titleTopCarTag">
                        <i class="el-icon-shopping-cart-2"></i>
                        <el-badge :value="0" :max="99" class="item">
                            <div>购物车</div>
                        </el-badge>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        activeNameCheck:String
    },
    data() {
        return {
            activeName:'1',
            value:''
        }
    },
    methods: {
        handleClickTop(e,e1){
            if (e.label == '患者病历') {
                this.$router.push({
                    name:'interrogationIndex'
                })
            }
            if (e.label == '患者列表') {
                this.$router.push({
                    name:'intPatientList'
                })
            }
            if (e.label == '个人常用处方') {
                this.$router.push({
                    name:'preintPatientList'
                })
            }
            if (e.label == '处方管理') {
                this.$router.push({
                    name:'PremangePatientList'
                })
            }
        },
    }
}
</script>
<style scoped>
    .titleTop {
        padding: 30px 20px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .titleTopLeft {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 15%;
    }
    .titleTopLeftImg {
        width: 125px;
        height: 30px;
    }
    .titleTopLeftImg img {
        width: 100%;
        height: 100%;
    }
    .titleTopSearch {
        display: flex;
        align-items: center;
    }
    ::v-deep .el-input__inner {
        border-radius: 20px;
        width: 540px;
        border: 1px solid #00AFB5;
    }
    .titleTopSearchInput {
        position: relative;
    }
    .btn {
        position: absolute;
        right: 0;
        top: 0;
    }
    .titleTopCar {
        margin-left: 10px;
    }
    .titleTopCarTag {
        padding: 5px 20px;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #00AFB5;
        border: 1px solid #00AFB5;
        border-radius: 20px;
    }
    .titleTopCarTag i {
        font-size: 18px;
        margin-right: 5px;
    }
</style>