<template>
    <div class="titleTop" >
        <div class="titleTopLeft">
            <div class="tabBox">
               <i class="el-icon-s-shop"></i> 千越商城首页
            </div>
        </div>
        <div class="titleTopRight">
            <div>  
                <el-dropdown>
                <span class="titleTopSize">
                    用户昵称<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item >个人中心</el-dropdown-item>
                    <el-dropdown-item >地址管理</el-dropdown-item>
                    <el-dropdown-item >消息通知</el-dropdown-item>
                    <el-dropdown-item >我的收藏</el-dropdown-item>
                    <el-dropdown-item >退出登录</el-dropdown-item>
                </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="titleTopRightD">我的订单</div>
            <div class="titleTopRightPhone">手机版<i class="el-icon-mobile-phone" style=""></i></div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        activeNameCheck:String
    },
    data() {
        return {
            activeName:'1'
        }
    },
    methods: {
        handleClickTop(e,e1){
            if (e.label == '患者病历') {
                this.$router.push({
                    name:'interrogationIndex'
                })
            }
            if (e.label == '患者列表') {
                this.$router.push({
                    name:'intPatientList'
                })
            }
            if (e.label == '个人常用处方') {
                this.$router.push({
                    name:'preintPatientList'
                })
            }
            if (e.label == '处方管理') {
                this.$router.push({
                    name:'PremangePatientList'
                })
            }
        },
    }
}
</script>
<style scoped>
    .titleTop {
        padding:5px 20px;
        background-color: #EBEBEB;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        color: #999999;
        cursor: pointer;
        width: 100%;
    }
    .titleTopLeft {
        /* width: 80%; */
        display: flex;
        align-items: center;
        padding-left:15% ;
    }
    .tabBox {
        /* padding-left: 12%; */
    }
    .tabBox i {
        color: #00AFB5;
        font-size: 14px;
        margin-right: 2px;
    }
    .titleTopSize {
         font-size: 12px;
        color: #999999;
    }
    .titleTopRightPhone i {
        color: #00AFB5;
        font-size: 14px;
        margin-left: 2px;
    }
    .titleTopRight {
        /* width: 20%; */
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding-right:15% ;
    }
    .titleTopRight div {
        margin-left: 10px;
    }
    .titleTopRightD {
        padding: 0 10px;
        border: 1px solid #CCCCCC;
        border-top:none ;
        border-bottom: none;
    }
</style>